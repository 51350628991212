<!-- 中型弹窗 -->
<template>
  <vh-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :dialogCenter="true"
    custom-class="std-dialog std-dialog--medium"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- NOTE: 自定义标题: 开发人员根据业务调整 -->
    <template slot="title">
      <BackHeader :showBack="false">
        <template slot="content">
          选择文档
          <vh-button type="text" @click="handleDemo" style="margin-left: 10px">
            {{ isNoData ? '演示有数据' : '演示空数据' }}
          </vh-button>
        </template>
      </BackHeader>
    </template>

    <!-- 内容区 -->
    <div class="dialog-body" v-loading="loading">
      <!-- NOTE: 空数据情况: 开发人员根据业务调整 -->
      <template v-if="isInited && isNoData">
        <null-page text="您还没有内容，快来创建吧！" :nullType="'nullData'">
          <vh-button size="medium" type="primary" round>主要按钮</vh-button>
          <vh-button size="medium" type="default" round plain>次要按钮</vh-button>
        </null-page>
      </template>

      <!-- 非空数据情况 -->
      <PageListBox v-if="isInited && !isNoData">
        <!-- NOTE: 搜索栏: 开发人员根据业务调整  -->
        <template slot="search">
          <vh-input
            round
            class="search-input"
            placeholder="请输入查询关键字"
            v-model="keyword"
            clearable
            v-clearEmoij
            size="medium"
            @clear="handleSearch"
            @keyup.enter.native="handleSearch"
          >
            <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
          </vh-input>
        </template>

        <!-- NOTE: 表格数据: 开发人员根据业务调整  -->
        <vh-table
          :data="dataList"
          style="width: 100%"
          height="360px"
          v-loadMore="loadMore"
          @cell-mouse-enter="handleCellMouseEnter"
          @cell-mouse-leave="handleCellMouseLeave"
          @selection-change="handleSelectionChange"
        >
          <template slot="empty">
            <null-page text="暂未搜索到您想要的内容" :nullType="'search'"></null-page>
          </template>
          <!-- 多选 -->
          <vh-table-column type="selection" width="60" />
          <!-- 名称 -->
          <vh-table-column prop="file_name" label="名称" min-width="160">
            <template slot-scope="scope">
              <vh-tooltip
                :disabled="!isTextOverflow"
                effect="dark"
                placement="top-start"
                :content="scope.row.file_name"
              >
                <div class="custom-tooltip-content">
                  <icon class="doc-icon" :icon-class="scope.row.ext | wordStatusCss"></icon>
                  {{ scope.row.file_name }}
                </div>
              </vh-tooltip>
            </template>
          </vh-table-column>

          <!-- 创建时间 -->
          <vh-table-column prop="created_at" label="创建时间" width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.created_at | dateFormat('YYYY-MM-DD HH:mm') }}</span>
            </template>
          </vh-table-column>

          <!-- 页码 -->
          <vh-table-column prop="page" label="页码" width="80"></vh-table-column>

          <!-- 转换进度 -->
          <vh-table-column label="状态" width="140">
            <template slot-scope="scope">
              <template v-for="item of scope.row.statusArr">
                <StatusText :type="item.statusDotType">
                  {{ item.statusName }}
                </StatusText>
              </template>
            </template>
          </vh-table-column>
        </vh-table>
      </PageListBox>
    </div>

    <!-- 底部操作栏 -->
    <!-- NOTE: 底部操作栏: 开发人员根据业务调整 -->
    <div slot="footer" class="dialog-footer" v-if="isInited && !isNoData">
      <div class="dialog-footer__hd">
        <div class="text-regular-8" style="display: inline; text-align: left">
          已选择
          <span class="fc-red">{{ multipleSelection.length }}</span>
          个文件
        </div>
      </div>
      <div class="dialog-footer__bd">
        <vh-button round type="info" size="medium" plain @click="$emit('cancel')">取消</vh-button>
        <vh-button round type="primary" size="medium" @click="submitForm('ruleForm')">
          确定
        </vh-button>
      </div>
    </div>
  </vh-dialog>
</template>
<script>
  import dialogMixin from './dialog-mixin.js';
  import NullPage from '@/components/NullPage';
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import PageListBox from '@/components/PageListBox';
  import dataJson from './data_doc.json';
  import StatusText from '@/components/StatusText/index.vue';
  import BackHeader from '@/components/BackHeader/index.vue';

  export default {
    name: 'DialogList',
    mixins: [tableCellTooltip, dialogMixin],
    components: {
      NullPage,
      PageListBox,
      StatusText,
      BackHeader
    },
    data() {
      return {
        isInited: false,
        isNoData: false,
        loading: false,

        keyword: '',
        multipleSelection: [],
        dataList: [],
        total: 0, // 总数量
        isEmpty: false,
        selectId: '',

        // 分页参数
        pagination: {
          limit: 10,
          pageNo: 1
        },

        // 测试数据源
        demoSourceData: []
      };
    },
    watch: {
      dialogVisible(val) {
        if (val) {
          this.demoSourceData = dataJson.list;
          this.fetchData();
        }
      }
    },
    methods: {
      handleDemo() {
        this.isInited = true;
        this.keyword = '';
        this.multipleSelection = [];
        this.dataList = [];
        this.total = 0;
        this.isEmpty = false;
        this.pagination.pageNo = 1;
        if (this.isNoData) {
          this.demoSourceData = dataJson.list;
        } else {
          this.demoSourceData = [];
        }
        this.fetchData();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleSearch() {
        this.pagination.pageNo = 1;
        this.fetchData();
      },
      loadMore() {
        if (this.dataList.length >= this.total && this.total > 0) return;
        console.log('---loadMore---');
        this.fetchData();
      },
      // 模拟请求数据
      fetchData: async function () {
        if (!this.isInited) {
          // 首次请求使用loading
          this.loading = true;
          // 模拟请求延时
          await new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 500);
          });
        }

        this.keyword = this.keyword.trim();
        const dataList = this.demoSourceData.filter(item => {
          return item.file_name.indexOf(this.keyword) > -1;
        });
        this.loading = false;
        // if (err || res?.code != 200) {
        //   this.$message.error(err?.msg || res?.msg || '请求数据失败');
        //   return;
        // }
        this.isInited = true;
        this.total = dataList.length || 0;
        this.isNoData = this.total === 0; // 没有数据
        this.isEmpty = this.total > 0 && this.keyword.length === 0; //搜索没有数据
        const pos = (this.pagination.pageNo - 1) * this.pagination.limit;
        const filterDataList = dataList.slice(pos, pos + this.pagination.limit);
        filterDataList.forEach(item => {
          const statusJpeg = +item.status_jpeg;
          const status = +item.status;
          if (statusJpeg === 0) {
            item.showEventType = 0;
            // prettier-ignore
            item.statusArr = [ { statusDotType: 'pending', statusName: '等待转码'}];
          } else if (statusJpeg === 100) {
            item.showEventType = 1;
            // prettier-ignore
            let _percent = (parseInt(item.converted_page_jpeg) / parseInt(item.page)) * 100;
            const process = (_percent + '').substr(0, 4);
            item.statusArr = [{ statusDotType: 'doing', statusName: process }];
          } else if (statusJpeg === 200) {
            if (/pptx?/.test(item.ext)) {
              // 如果是ppt or pptx
              if (status === 0) {
                item.showEventType = 2;
                item.statusArr = [{ statusDotType: 'pending', statusName: '等待转码' }];
              } else if (status === 100) {
                item.showEventType = 3;
                item.statusArr = [
                  { statusDotType: 'success', statusName: '静态转码成功' },
                  { statusDotType: 'doing', statusName: '动态转码中' }
                ];
              } else if (status === 200) {
                item.showEventType = 4;
                item.statusArr = [
                  { statusDotType: 'success', statusName: '静态转码成功' },
                  { statusDotType: 'success', statusName: '动态转码成功' }
                ];
              } else {
                item.showEventType = 5;
                item.statusArr = [
                  { statusDotType: 'success', statusName: '静态转码成功' },
                  { statusDotType: 'failure', statusName: '动态转码失败' }
                ];
              }
            } else {
              // 非PPT静态转码完成
              item.showEventType = 6;
              item.statusArr = [{ statusDotType: 'success', statusName: '转码成功' }];
            }
          } else if (statusJpeg >= 500) {
            item.showEventType = 7;
            item.statusArr = [{ statusDotType: 'failure', statusName: '转码失败' }];
          }
        });
        if (this.pagination.pageNo == 1) {
          this.pagination.pageNo = this.pagination.pageNo + 1;
          this.dataList = filterDataList;
        } else {
          this.pagination.pageNo = this.pagination.pageNo + 1;
          this.dataList.push(...filterDataList);
        }
      }
    }
  };
</script>
