<!-- 小型弹窗 - 分组设置 -->
<template>
  <vh-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :dialogCenter="true"
    title="分组设置"
    custom-class="std-dialog std-dialog--small"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- 内容区 -->
    <div class="dialog-body">
      <vh-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="std-form">
        <!-- 分组数量 -->
        <vh-form-item label="分组数量" prop="count">
          <span>将当前 0 观众分成</span>
          <vh-input
            v-model.number="ruleForm.count"
            placeholder="2-50"
            size="medium"
            class="ml-4"
            style="width: 110px"
          >
            <span slot="suffix" class="lh-n">小组</span>
          </vh-input>
        </vh-form-item>
        <!-- 分组方式 -->
        <vh-form-item label="分组方式" prop="way" class="auto-height">
          <!-- prettier-ignore -->
          <vh-radio-group v-model="ruleForm.way" class="is-vertical">
          <vh-radio :label="1">自动分组<span class="text-regular-8 fc-grey-6"> (系统随机分配组内成员)</span></vh-radio>
          <vh-radio :label="2">手动分组<span class="text-regular-8 fc-grey-6">  (按照实际情况及个人意愿分配)</span></vh-radio>
          <vh-radio :label="3">白名单预设分组<span class="text-regular-8 fc-grey-6"> (按照白名单观众组中的分组分配)</span></vh-radio>
        </vh-radio-group>
        </vh-form-item>

        <!-- 调取数量 -->
        <vh-form-item label="调取数量" prop="pollingCount">
          <div class="content-title">每组调取的视频数≤最大上麦数-已上麦数</div>
          <div class="content-body lh-n">
            <span class="text-regular-8 lh-36">每组调取的视频数</span>
            <vh-select
              v-model="ruleForm.pollingCount"
              placeholder="请选择"
              size="medium"
              class="ml-4"
              style="width: 110px"
            >
              <vh-option :label="1" :value="1"></vh-option>
              <vh-option :label="4" :value="4"></vh-option>
              <vh-option :label="9" :value="9"></vh-option>
            </vh-select>
          </div>
        </vh-form-item>

        <!-- 轮询方式 -->
        <vh-form-item label="轮询方式" prop="pollingWay" class="auto-height mb-0">
          <!-- prettier-ignore -->
          <vh-radio-group v-model="ruleForm.pollingWay" class="is-vertical">
          <vh-radio :label="1">手动轮询<span class="text-regular-8 fc-grey-6"> (点击“视频墙”页面中的“下一组”按钮，切换画面)</span></vh-radio>
          <vh-radio :label="2">自动轮询<span class="text-regular-8 fc-grey-6"> (展示时间结束后，自动切换到下一组视频画面)</span></vh-radio>
          <div style="margin-top:9px;">
            <span class="text-regular-8 lh-36">展示时间</span>
            <vh-select
              v-model="ruleForm.pollingTime"
              placeholder="请选择"
              size="medium"
              class="ml-4"
              :disabled="ruleForm.pollingWay==1"
              style="width: 110px"
            >
              <vh-option label="0.5分钟" :value="0.5"></vh-option>
              <vh-option label="1分钟" :value="1"></vh-option>
              <vh-option label="5分钟" :value="5"></vh-option>
              <vh-option label="10分钟" :value="10"></vh-option>
            </vh-select>
        </div>
        </vh-radio-group>
        </vh-form-item>
      </vh-form>
    </div>

    <!-- 底部操作栏 -->
    <template slot="footer" class="dialog-footer">
      <vh-button round type="info" size="medium" plain @click="$emit('cancel')">取消</vh-button>
      <vh-button round type="primary" size="medium" @click="submitForm('ruleForm')">
        开始分组
      </vh-button>
    </template>
  </vh-dialog>
</template>
<script>
  import dialogMixin from './dialog-mixin.js';
  export default {
    mixins: [dialogMixin],
    data() {
      const countValidate = (rule, value, callback) => {
        if (!value) {
          callback(new Error('分组数量'));
        } else {
          if (value >= 2 || value <= 50) {
            callback();
          } else {
            callback && callback('分组数量在 2-50 之间');
          }
        }
      };

      return {
        currentUserCount: 0,
        ruleForm: {
          count: undefined,
          way: 1,
          pollingCount: 1,
          pollingWay: 1,
          pollingTime: 1 // 展示时间
        },
        rules: {
          // count: [
          //   { required: true, message: '请输入分组数量', trigger: 'blur' },
          //   { validator: countValidate, trigger: 'blur' }
          // ]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  };
</script>
