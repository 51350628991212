<!-- 小型弹窗 - 二维码分享 -->
<template>
  <vh-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :dialogCenter="true"
    title="分享"
    custom-class="std-dialog std-dialog--small"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="dialog-body">
      <div class="share-container">
        <vh-image
          style="width: 130px; height: 130px"
          src="https://aliqr.e.vhall.com/qr.png?s=7&t=https%3A%2F%2Ftest01-live.vhall.com%2Fv3%2Flives%2Fwatch%2F528909709%3FshareId%3Dv-v1829340882878447616-3"
        ></vh-image>
        <p class="text-regular-8 fc-grey-6" style="margin-top: 9px">
          使用微信“扫一扫”，即可将活动分享至好友或朋友圈
        </p>
      </div>
    </div>
  </vh-dialog>
</template>
<script>
  import dialogMixin from './dialog-mixin.js';
  export default {
    mixins: [dialogMixin]
  };
</script>
<style lang="less" scoped>
  .share-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
