<!-- 小型弹窗 - 分享 -->
<template>
  <vh-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :dialogCenter="true"
    title="分享"
    custom-class="std-dialog std-dialog--small"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="dialog-body">
      <div class="share-container">
        <div class="share-container__hd">
          <div class="image-text" @click="$emit('goshare', 'wechat')">
            <vh-image style="" :src="wechatImg"></vh-image>
            <p class="text-regular-8">微信</p>
          </div>
          <div class="image-text">
            <vh-image style="width: 40px; height: 40px" :src="qqImg"></vh-image>
            <p class="text-regular-8">QQ</p>
          </div>
          <div class="image-text">
            <vh-image style="width: 40px; height: 40px" :src="weiboImg"></vh-image>
            <p class="text-regular-8">微博</p>
          </div>
        </div>

        <div class="share-container__bd">
          <vh-input id="vmp-share-watch" v-model="shareUrl" size="medium" readOnly>
            <vh-button slot="append" size="medium">复制</vh-button>
          </vh-input>
        </div>
      </div>
    </div>
  </vh-dialog>
</template>
<script>
  import dialogMixin from './dialog-mixin.js';
  export default {
    mixins: [dialogMixin],
    data() {
      return {
        shareUrl: 'https://test01-live.vhall.com/v3/lives/watch/528909709',
        wechatImg: require('./images/wechat@2x.png'),
        qqImg: require('./images/qq@2x.png'),
        weiboImg: require('./images/weibo@2x.png')
      };
    }
  };
</script>
<style lang="less" scoped>
  .share-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__hd {
      flex: 1 1 0;
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      align-self: flex-start;

      .image-text {
        width: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .vh-image {
          width: 40px;
          height: 40px;
        }

        p {
          margin-top: 4px;
          text-align: center;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .image-text:not(:first-child) {
        margin-left: 64px;
      }
    }
    &__bd {
      width: 100%;
    }
  }
</style>
