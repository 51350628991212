<template>
  <!-- 大型弹窗 -->
  <vh-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :dialogCenter="true"
    custom-class="std-dialog std-dialog--big"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- NOTE: 自定义标题: 开发人员根据业务调整 -->
    <template slot="title">
      <BackHeader
        content="弹窗标题"
        :showBack="false"
        :showRefresh="true"
        @refresh="handleSearch"
      ></BackHeader>
    </template>

    <!-- 内容区 -->
    <div class="dialog-body" v-loading="loading">
      <!-- NOTE: 空数据情况: 开发人员根据业务调整 -->
      <template v-if="isInited && isNoData">
        <null-page text="您还没有内容，快来创建吧！" :nullType="'nullData'">
          <vh-button size="medium" type="primary" round>主要按钮</vh-button>
          <vh-button size="medium" type="default" round plain>次要按钮</vh-button>
        </null-page>
      </template>

      <!-- 非空数据情况 -->
      <PageListBox v-if="isInited && !isNoData">
        <!-- NOTE: 搜索栏: 开发人员根据业务调整  -->
        <template slot="search">
          <vh-button size="medium" type="primary" round @click="$emit('create')">
            创建卡片
          </vh-button>
          <vh-input
            round
            class="search-input"
            placeholder="请输入查询关键字"
            v-model="keyword"
            clearable
            v-clearEmoij
            size="medium"
            @clear="handleSearch"
            @keyup.enter.native="handleSearch"
          >
            <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
          </vh-input>
        </template>

        <!-- NOTE: 表格数据: 开发人员根据业务调整  -->
        <vh-table
          :data="dataList"
          style="width: 100%"
          height="360px"
          v-loadMore="loadMore"
          @cell-mouse-enter="handleCellMouseEnter"
          @cell-mouse-leave="handleCellMouseLeave"
          @selection-change="handleSelectionChange"
        >
          <template slot="empty">
            <null-page text="暂未搜索到您想要的内容" :nullType="'search'"></null-page>
          </template>
          <!-- 多选 -->
          <vh-table-column type="selection" width="60" />

          <!-- 方图片-->
          <vh-table-column prop="img_path" label="图片标题2" width="100">
            <template slot-scope="scope">
              <div class="image-preview">
                <vh-image
                  style="height: 72px; width: 72px"
                  :src="scope.row.img_path"
                  :preview-src-list="[scope.row.img_path]"
                ></vh-image>
              </div>
            </template>
          </vh-table-column>

          <!-- 名称 -->
          <vh-table-column prop="name" label="名称" min-width="120">
            <template slot-scope="scope">
              <vh-tooltip
                :disabled="!isTextOverflow"
                effect="dark"
                placement="top-start"
                :content="scope.row.name"
              >
                <div class="custom-tooltip-content">{{ scope.row.name }}</div>
              </vh-tooltip>
            </template>
          </vh-table-column>
          <!-- 创建时间 -->
          <vh-table-column prop="create_time" label="创建时间" width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.create_time | dateFormat('YYYY-MM-DD HH:mm') }}</span>
            </template>
          </vh-table-column>

          <!-- 数量 -->
          <vh-table-column prop="num" label="数量" width="80"></vh-table-column>

          <!-- 状态 -->
          <vh-table-column label="状态" width="100">
            <template slot="header">
              状态
              <vh-tooltip effect="dark" placement="top-start" v-tooltipMove>
                <div slot="content">这是状态描述</div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </template>
            <template slot-scope="scope">
              <StatusText :type="scope.row.statusDotType">
                {{ scope.row.statusName }}
              </StatusText>
            </template>
          </vh-table-column>

          <!-- 最后操作 -->
          <vh-table-column prop="last_time" label="最后操作" width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.modifier }}</p>
              <span>{{ scope.row.last_time | dateFormat('YYYY-MM-DD HH:mm') }}</span>
            </template>
          </vh-table-column>
        </vh-table>
      </PageListBox>
    </div>

    <!-- NOTE: 底部操作栏: 开发人员根据业务调整 -->
    <div slot="footer" class="dialog-footer" v-if="isInited && !isNoData">
      <div class="dialog-footer__hd">
        <div class="text-regular-8" style="display: inline; text-align: left">
          已选择
          <span class="fc-red">{{ multipleSelection.length }}</span>
          个文件
        </div>
      </div>
      <div class="dialog-footer__bd">
        <vh-button round type="info" size="medium" plain @click="$emit('cancel')">取消</vh-button>
        <vh-button round type="primary" size="medium" @click="submitForm('ruleForm')">
          确定
        </vh-button>
      </div>
    </div>
  </vh-dialog>
</template>
<script>
  import dialogMixin from './dialog-mixin.js';
  import NullPage from '@/components/NullPage';
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import PageListBox from '@/components/PageListBox';
  import dataJson from './data.json';
  import StatusText from '@/components/StatusText/index.vue';
  import BackHeader from '@/components/BackHeader/index.vue';

  export default {
    name: 'DialogList',
    mixins: [tableCellTooltip, dialogMixin],
    components: {
      NullPage,
      PageListBox,
      StatusText,
      BackHeader
    },
    data() {
      return {
        isInited: false,
        isNoData: false, // 无任何数据
        loading: false,

        keyword: '',
        multipleSelection: [],
        dataList: [],
        total: 0, // 总数量
        isEmpty: false, // 搜索没有数据
        selectId: '',

        // 分页参数
        pagination: {
          limit: 10,
          pageNo: 1
        },
        // 测试数据源
        demoSourceData: []
      };
    },
    watch: {
      dialogVisible(val) {
        if (val) {
          this.demoSourceData = dataJson.list;
          this.fetchData();
        }
      }
    },
    methods: {
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleSearch() {
        this.pagination.pageNo = 1;
        this.fetchData();
      },
      loadMore() {
        if (this.dataList.length >= this.total && this.total > 0) return;
        console.log('---loadMore---');
        this.fetchData();
      },
      // 模拟请求数据
      fetchData: async function () {
        if (!this.isInited) {
          // 首次请求使用loading
          this.loading = true;
          // 模拟请求延时
          await new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 800);
          });
        }

        this.keyword = this.keyword.trim();
        const dataList = this.demoSourceData.filter(item => {
          return item.name.indexOf(this.keyword) > -1;
        });
        this.loading = false;
        // if (err || res?.code != 200) {
        //   this.$message.error(err?.msg || res?.msg || '请求数据失败');
        //   return;
        // }
        this.isInited = true;
        this.total = dataList.length || 0;
        this.isNoData = this.total === 0; // 没有数据
        this.isEmpty = this.total > 0 && this.keyword.length === 0; //搜索没有数据
        const pos = (this.pagination.pageNo - 1) * this.pagination.limit;
        const filterDataList = dataList.slice(pos, pos + this.pagination.limit);
        filterDataList.forEach(item => {
          if (item.status === 1) {
            item.statusDotType = 'doing';
            item.statusName = '推送中';
          } else if (item.status === 2) {
            item.statusDotType = 'success';
            item.statusName = '已推送';
          } else {
            item.statusDotType = 'pending';
            item.statusName = '未推送';
          }
        });
        if (this.pagination.pageNo == 1) {
          this.pagination.pageNo = this.pagination.pageNo + 1;
          this.dataList = filterDataList;
        } else {
          this.pagination.pageNo = this.pagination.pageNo + 1;
          this.dataList.push(...filterDataList);
        }
      }
    }
  };
</script>
