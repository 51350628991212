<!-- 小型弹窗 - 简单的弹窗表单 -->
<template>
  <vh-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :dialogCenter="true"
    title="弹窗标题"
    custom-class="std-dialog std-dialog--small"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="dialog-body mb-0">
      <div class="text-regular-8 mb-24 fc-grey-6">
        建议渠道名称、参数结合投放的位置进行命名，方便理解与追踪；例如：微信公众号、wechat。渠道参数一旦创建不可修改。
      </div>
      <vh-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="std-form">
        <!-- 礼物价格 -->
        <vh-form-item label="图片上传" required>
          <vh-upload class="content-upload" action="https://jsonplaceholder.typicode.com/posts/">
            <i class="iconfont-v3 saasicon_shangchuan"></i>
            <div class="text-tips">
              <p>建议尺寸：240*240px，小于2M</p>
              <p>支持jpg、gif、png、bmp</p>
            </div>
          </vh-upload>
        </vh-form-item>
        <!-- 渠道名称 -->
        <vh-form-item label="渠道名称" prop="name" required>
          <vh-input
            v-model="ruleForm.name"
            placeholder="请输入渠道名称"
            maxlength="10"
            size="medium"
            show-word-limit
          ></vh-input>
        </vh-form-item>

        <!-- 礼物价格 -->
        <vh-form-item label="礼物价格" prop="price" required class="mb-24">
          <vh-input v-model="ruleForm.price" placeholder="只允许输入0" size="medium">
            <i slot="suffix" class="lh-n">元</i>
          </vh-input>
        </vh-form-item>
      </vh-form>
    </div>

    <!-- 底部操作栏 -->
    <template slot="footer" class="dialog-footer">
      <vh-button round type="info" size="medium" plain @click="$emit('cancel')">取消</vh-button>
      <vh-button round type="primary" size="medium" @click="submitForm('ruleForm')">确定</vh-button>
    </template>
  </vh-dialog>
</template>
<script>
  import dialogMixin from './dialog-mixin.js';
  export default {
    name: 'DialogForm',
    mixins: [dialogMixin],
    data() {
      const priceValidate = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入礼物价格'));
        } else {
          if (value != 0) {
            callback && callback('价格必须等于0');
          } else if (value.length - value.indexOf('.') > 3 && value.indexOf('.') > -1) {
            callback && callback('价格最多支持两位小数');
          } else {
            callback();
          }
        }
      };

      return {
        ruleForm: {
          name: '',
          price: undefined
        },
        rules: {
          name: [
            { required: true, message: '请输入渠道名称', trigger: 'blur' },
            { min: 1, max: 20, message: '长度在 1 到 10 个字符', trigger: 'blur' }
          ],
          price: [
            { required: true, message: '请输入价格', trigger: 'blur' },
            { validator: priceValidate, trigger: 'blur' }
          ]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  };
</script>
