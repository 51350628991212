<template>
  <PageContainer>
    <template slot="title">
      <!-- NOTE: 标题栏: 开发人员根据业务调整 -->
      <pageTitle pageTitle="UI规范弹窗大集合">
        <div slot="content">
          1. 弹窗中的表单项，输入框、按钮都使用36px；
          <br />
        </div>
        <HelpPopover />
      </pageTitle>
    </template>

    <template slot="content">
      <div class="dialog-demo">
        <h3>微型弹窗--提示类</h3>
        <div class="dialog-demo__block">
          <vh-button type="text" round @click="handleOpenMini_1">一般提示</vh-button>
          <vh-button type="text" round @click="handleOpenMini_2">确认提示</vh-button>
          <vh-button type="text" round @click="handleOpenMini_3">自定义提示</vh-button>
          <vh-button type="text" round @click="handleOpenMini_4">分享</vh-button>
        </div>

        <h3>小型弹窗--常规表单类</h3>
        <div class="dialog-demo__block">
          <vh-button type="text" round @click="handleOpen_small_1">常规表单</vh-button>
          <vh-button type="text" round @click="handleOpen_small_2">分组/轮询设置</vh-button>
        </div>

        <h3>中型弹窗--常规表单类</h3>
        <div class="dialog-demo__block">
          <vh-button type="text" round @click="handleOpen_medium_1">常规列表</vh-button>
        </div>

        <h3>大型弹窗--复杂表单类、复杂列表类</h3>
        <div class="dialog-demo__block">
          <vh-button type="text" round @click="handleOpen_big_1">大型弹窗表单</vh-button>
        </div>
      </div>

      <!-- 微型弹窗-分享1 -->
      <DialogShareWechat
        :visible="dialogVisible_mini_4"
        :before-close="handleClose_mini_4"
        @goshare="handleWeChatShare"
      ></DialogShareWechat>

      <!-- 微型弹窗-分享2 -->
      <DialogShareQr
        :visible="dialogVisible_mini_5"
        :before-close="handleClose_mini_5"
      ></DialogShareQr>

      <!-- 小型弹窗 -->
      <DialogForm
        :visible="dialogVisible_small_1"
        :before-close="handleClose_small_1"
        @cancel="handleClose_small_1"
      ></DialogForm>

      <!-- 小型弹窗 - 分组设置 -->
      <DialogGroup
        :visible="dialogVisible_small_2"
        :before-close="handleClose_small_2"
        @cancel="handleClose_small_2"
      ></DialogGroup>

      <!-- 中型弹窗 -->
      <DialogList
        :visible="dialogVisible_medium_1"
        :before-close="handleClose_medium_1"
        @cancel="handleClose_medium_1"
      ></DialogList>

      <!-- 大型弹窗-列表 -->
      <DialogList2
        :visible="dialogVisible_big_1"
        :before-close="handleClose_big_1"
        @cancel="handleCancel_big_1"
        @create="handleOpen_big_2"
      ></DialogList2>

      <!-- 大型弹窗-表单 -->
      <DialogForm2
        :visible="dialogVisible_big_2"
        :before-close="handleClose_big_2"
        @cancel="handleCancel_big_2"
        @back="handleCancel_big_2"
      ></DialogForm2>
    </template>
  </PageContainer>
</template>

<script>
  import HelpPopover from './HelpPopover.vue';
  import PageContainer from '@/components/PageContainer';
  import PageTitle from '@/components/PageTitle';
  import DialogShareWechat from './DialogShareWechat.vue';
  import DialogShareQr from './DialogShareQr.vue';
  import DialogForm from './DialogForm.vue';
  import DialogGroup from './DialogGroup.vue';
  import DialogForm2 from './DialogForm2.vue';
  import DialogList from './DialogList.vue';
  import DialogList2 from './DialogList2.vue';

  export default {
    components: {
      HelpPopover,
      PageContainer,
      PageTitle,
      DialogShareWechat,
      DialogShareQr,
      DialogForm,
      DialogGroup,
      DialogForm2,
      DialogList,
      DialogList2
    },
    data() {
      return {
        dialogVisible_mini_4: false,

        dialogVisible_mini_5: false,

        dialogVisible_small_1: false,
        dialogVisible_small_2: false,

        dialogVisible_medium_1: false,

        dialogVisible_big_1: false,
        dialogVisible_big_2: false
      };
    },
    methods: {
      // 一般提示
      handleOpenMini_1() {
        this.$vhAlert('这是一行文字描述。这是一行文字描述。', '标题名称', {
          confirmButtonText: '确定',
          roundButton: true,
          callback: action => {
            this.$vhMessage({
              type: 'info',
              message: `action: ${action}`
            });
          }
        });
      },
      // 确认提示
      handleOpenMini_2() {
        this.$vhConfirm(
          '删除回放会导致目前已生成回放的数据丢失，请谨慎操作，确定要删除这段回放么?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            roundButton: true
          }
        )
          .then(() => {
            this.$vhMessage({
              type: 'success',
              message: '删除成功!'
            });
          })
          .catch(() => {
            this.$vhMessage({
              type: 'info',
              message: '已取消删除'
            });
          });
      },
      // 自定义提示
      handleOpenMini_3() {
        const maxSpeakCount = 'xx';
        const h = this.$createElement;
        this.$vhAlert('', '', {
          title: '提示',
          message: h('div', null, [
            h(
              'p',
              { style: 'padding-bottom: 5px' },
              `您的连麦数已达到${maxSpeakCount}上限，暂无法开启视频轮巡功能，您可将观众下麦，再开启视频轮巡功能`
            ),
            h('p', { style: 'color: #999' }, '注：每组调取的视频数<=当前活动连麦数-已上麦数')
          ]),
          confirmButtonText: '知道了',
          roundButton: true
        });
      },
      // 分享
      handleOpenMini_4() {
        this.dialogVisible_mini_4 = true;
      },
      handleClose_mini_4() {
        this.dialogVisible_mini_4 = false;
      },
      // 点击微信图标分享
      handleWeChatShare() {
        // 二维码分享
        this.dialogVisible_mini_5 = true;
      },
      handleClose_mini_5() {
        this.dialogVisible_mini_5 = false;
      },
      // 小型弹窗 - 常规表单
      handleOpen_small_1() {
        this.dialogVisible_small_1 = true;
      },
      handleClose_small_1() {
        this.dialogVisible_small_1 = false;
      },
      // 小型弹窗 - 分组设置
      handleOpen_small_2() {
        this.dialogVisible_small_2 = true;
      },
      handleClose_small_2() {
        this.dialogVisible_small_2 = false;
      },
      handleOpen_medium_1() {
        this.dialogVisible_medium_1 = true;
      },
      handleClose_medium_1() {
        this.dialogVisible_medium_1 = false;
      },
      handleOpen_big_1() {
        this.dialogVisible_big_1 = true;
      },
      handleClose_big_1() {
        this.dialogVisible_big_1 = false;
        this.dialogVisible_big_2 = false;
      },
      handleCancel_big_1() {
        this.dialogVisible_big_1 = false;
      },
      handleOpen_big_2() {
        this.dialogVisible_big_2 = true;
      },
      handleClose_big_2() {
        this.dialogVisible_big_1 = false;
        this.dialogVisible_big_2 = false;
      },
      handleCancel_big_2() {
        this.dialogVisible_big_2 = false;
      },
      handleClose_test() {
        this.dialogVisible_test = false;
      }
    }
  };
</script>
<style lang="less" scoped>
  .dialog-demo {
    width: 100%;
    padding: 24px;

    h3 {
      font-size: 20px;
      line-height: 1.5;
    }

    &__block {
      margin: 12px 0 32px 0;
    }
  }
</style>
