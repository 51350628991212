<template>
  <div class="back-header">
    <div class="back-header__left" v-if="showBack" @click="$emit('back')">
      <i class="vh-iconfont vh-line-arrow-left"></i>
    </div>
    <div class="back-header__content">
      <slot name="content">
        {{ content }}
      </slot>
      <vh-button v-if="showRefresh" type="text" class="button-refresh" @click="$emit('refresh')">
        刷新
      </vh-button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'BackHeader',
    props: {
      content: {
        type: String,
        default: undefined
      },
      showBack: {
        type: Boolean,
        default: true
      },
      showRefresh: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
<style lang="less">
  .back-header {
    display: inline-flex;
    height: 26px;
    line-height: 26px;
    align-items: center;

    &__left {
      display: flex;
      cursor: pointer;
      align-items: center;
      position: relative;

      .vh-line-arrow-left {
        font-size: 14px;
        margin-right: 9px;
        color: #262626;
        align-self: center;
      }
    }
    &__content {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: #262626;
      display: flex;
      align-items: center;

      .button-refresh {
        margin-left: 9px;
      }
    }
  }
</style>
