import { render, staticRenderFns } from "./DialogShareWechat.vue?vue&type=template&id=1f43a1e0&scoped=true&"
import script from "./DialogShareWechat.vue?vue&type=script&lang=js&"
export * from "./DialogShareWechat.vue?vue&type=script&lang=js&"
import style0 from "./DialogShareWechat.vue?vue&type=style&index=0&id=1f43a1e0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f43a1e0",
  null
  
)

export default component.exports